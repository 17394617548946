<!--
 * @Author: 'Lizz' lzz1961690348@163.com
 * @Date: 2023-04-17 11:10:50
 * @LastEditors: yangzebin
 * @LastEditTime: 2024-04-18 10:11:53
 * @FilePath: \yda_web_manage\src\views\approval\informPeople\index.vue
 * @Description: 抄送我的列表3.0
-->
<template>
  <template v-if="pageLoading">
    <a-spin size="large" class="loading-animation" />
  </template>
  <template v-else>
    <section style="margin: 0 24px">
      <section class="form-style">
        <!-- 头部 -->
        <a-form layout="inline" ref="headFormRef" :model="headFormState">
          <!-- 搜索框 -->
          <a-form-item label="流程主题:" class="form-center" name="processTopic">
            <a-input
              style="width: 200px;height: 32px;"
              v-model:value.trim="headFormState.processTopic"
              :maxlength="50"
              placeholder="请输入"
              allowClear
            />
          </a-form-item>
          <!-- 流程名称 -->
          <a-form-item label="申请人:" name="processStaffName">
            <a-input
              style="width: 200px;height: 32px;"
              v-model:value.trim="headFormState.processStaffName"
              :maxlength="30"
              placeholder="请输入"
              allowClear
            />
          </a-form-item>
          <!-- 文件类型 -->
          <a-form-item label="流程类型:" name="processMold">
            <a-select
              style="width: 200px;height: 32px;"
              v-model:value="headFormState.processMold"
              allowClear
              placeholder="请选择"
              class="form-select"
            >
              <a-select-option value="1">
                用印流程
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="流程名称:" name="processName">
            <a-input
              style="width: 200px;height: 32px;"
              v-model:value.trim="headFormState.processName"
              :maxlength="30"
              placeholder="请输入"
              allowClear
            />
          </a-form-item>
          <a-form-item label="抄送时间:" name="submitTime">
            <a-range-picker
              :disabledDate="disabledDate"
              separator="→"
              allowClear
              v-model:value="headFormState.submitTime"
              style="width: 240px;height: 32px;"
            >
              <template #suffixIcon>
                <CalendarOutlined />
              </template>
            </a-range-picker>
          </a-form-item>
          <!-- 重置/查询 -->
          <a-form-item class="form-btns">
            <a-button @click="reset">重置</a-button>
            <a-button class="form-btn" @click="searchList">查询</a-button>
          </a-form-item>
        </a-form>
      </section>
      <!-- 表格数据 -->
      <section class="table-style">
        <div class="table-head">
          <span class="title">抄送列表</span>
        </div>
        <a-table
          :columns="informPeopleColumns"
          :pagination="pagination"
          :dataSource="dataSource"
          @change="handleTableChange"
          :loading="tableLoading"
          :sortDirections="['ascend', 'descend']"
          :rowKey="
            (record, index) => {
              return index
            }
          "
          ref="tableRef"
          :scroll="{ x: 400 }"
        >
          <!-- 序号 -->
          <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
          <!-- 流程名称 -->
          <template #processName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 流程主题 -->
          <template #fileName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <template #processMold="{ text }">
            {{ processType[text - 1].name ?? '-' }}
          </template>
          <!-- 申请人 -->
          <template #applyStaffName="{ record }">
            <div class="text-box" v-if="record.applyStaffName">
              <a-tooltip
                placement="bottomLeft"
                v-if="record.applyStaffName?.length > 4 ||  record.applyDepartmentName?.length >6"
              >
                <template #title>
                  <span>{{ record.applyStaffName + '-' + record.applyDepartmentName }}</span>
                </template>
                {{ applyFilter(record.applyStaffName, record.applyDepartmentName) ?? '-' }}
              </a-tooltip>
              <span v-else>{{ applyFilter(record.applyStaffName, record.applyDepartmentName) ?? '-' }}</span>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <!-- 审批人 -->
          <template #examineNames="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 11">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 印章类型 -->
          <template #sealTypeName="{ text }">
            <span class="sealTypeName" :title="text">{{ text ?? '-' }}</span>
          </template>
          <!-- 部门 -->
          <template #deptName="{ text }">
            <span :title="text">{{ text ?? '-' }}</span>
          </template>
          <!-- 操作 -->
          <template #action="{ record }">
            <a @click="pushDetails(record)" class="table-text-color">查看</a>
          </template>
        </a-table>
      </section>
    </section>
  </template>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { informPeopleColumns } from '../columns'
import { getCarbonCopy } from '@/apis/approval'
import { examineStatusObj, paginationParams, searchType, processType } from '@/utils/constData'
import { setTablePageFooterStyle } from '@/utils/utils'
import { useRouter } from 'vue-router'
import { CalendarOutlined } from '@ant-design/icons-vue'
import { getEnums } from '@/apis/user'
import moment from 'moment'

export default defineComponent({
  components: {
    CalendarOutlined
  },
  setup() {
    const pageLoading = ref(true)
    const router = useRouter()
    const tableRef = ref()
    const headFormRef = ref()
    const headFormState = reactive({
      processTopic: undefined, //流程主题
      processStaffName: undefined, //申请人
      processMold: undefined, //流程类型
      processName: undefined, //流程名称
      submitTime: [] //申请时间
    })
    const state = reactive({
      documentId: undefined,
      timeSort: '',
      pagination: {
        ...paginationParams
      },
      processType: processType,
      dataSource: [],
      current: undefined,
      tableLoading: true,
      currentNum: 1,
      searchTypeOptions: [] //搜索项类型
    })
    const getList = async () => {
      // 列表展示数据
      state.tableLoading = true
      state.dataSource = []
      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        isPagination: true,
        orderByRecordTime: state.timeSort, //根据抄送时间倒序
        search: headFormState.searchVal, //模糊查询内容
        processTopic: headFormState.processTopic, //模糊查询内容
        processStaffName: headFormState.processStaffName, //搜索项类型
        processMold: headFormState.processMold, //流程名称模糊搜索
        processName: headFormState.processName,
        startTime: headFormState.submitTime[0] ? moment(headFormState.submitTime[0]).format('YYYY-MM-DD 00:00:00') : '', //开始时间
        endTime: headFormState.submitTime[1] ? moment(headFormState.submitTime[1]).format('YYYY-MM-DD 23:59:59') : '' //结束时间
      }
      const res = await getCarbonCopy(params)
      console.log('返回数据', res.data)
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
      state.documentId = undefined
    }
    //查看
    const pushDetails = async current => {
      console.log('查看current', current)
      router.push({
        path: '/approval/informPeople/detail',
        query: {
          dataId: current.dataId,
          processInstanceId: current.processInstanceId,
          hasCover: current.hasCover
        }
      })
      // if (current.sealType === 1) {
      //   //实体章
      //   // router.push(
      //   //   `/approval/informPeople/entityinformdetail?documentId=${current.documentId}&sealId=${current.sealId}`
      //   // )
      //   console.log('跳转至抄送详情')
      //   router.push(`/approval/informPeople/ccdetail?documentId=${current.documentId}&sealId=${current.sealId}`)
      // } else if (current.sealType === 2) {
      //   //电子章
      //   router.push(`/approval/informPeople/elecinformdetail?documentId=${current.documentId}`)
      // }
    }
    //流程类型
    const getprocessMold = value => {
      const textMap = {
        1: '用印流程',
        2: '归档流程',
        3: '刻章流程',
        4: '变更流程',
        5: '装卸流程',
        6: '移交流程',
        7: '销毁流程'
      }
      return textMap[value] || '-'
    }
    const applyFilter = (name, depName, type) => {
      let val, n, d
      if (depName) {
        name?.length > 4 ? (n = `${name.slice(0, 4)}...`) : (n = name)
        depName?.length > 6 ? (d = `${depName.slice(0, 6)}...`) : (d = depName)
        val = n + '-' + d
      } else {
        val = name
      }
      return val
    }
    // 日期选择器：当天之后不可选
    //搜索
    const searchList = () => {
      // 查询列表数据
      state.pagination.current = 1
      getList()
    }
    //重置
    const reset = () => {
      headFormRef.value.resetFields()
      headFormState.searchType = 1
      state.pagination.current = 1
      getList()
    }
    // 列表的页码change事件
    const handleTableChange = ({ current, pageSize }, filters, { order }) => {
      state.timeSort = ''
      if (order == 'ascend') {
        state.timeSort = 1
      }
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      state.dataSource = []
      getList()
    }

    // 日期选择器：当天之后不可选
    const disabledDate = current => {
      return current && current > moment().endOf('day')
    }

    onMounted(async () => {
      await getEnums({ kind: 'DOCUMENT_SEARCH_TYPE' }).then(res => {
        if (res.success) {
          state.searchTypeOptions = res.data
          console.log('res.data的值', res.data)
        }
      })
      await getList()
      pageLoading.value = false
    })
    return {
      ...toRefs(state),
      searchList,
      handleTableChange,
      pushDetails,
      examineStatusObj,
      tableRef,
      headFormState,
      headFormRef,
      reset,
      getprocessMold,
      disabledDate,
      searchType,
      informPeopleColumns,
      pageLoading,
      applyFilter
    }
  }
})
</script>

<style lang="scss" scoped>
.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .ant-select {
  .ant-select-selector {
    height: 32px;
    border-radius: 4px;
    line-height: 32px;
  }
  .ant-select-selection-placeholder {
    line-height: 32px;
  }
  .ant-select-selection-item {
    line-height: 32px;
  }
}
.dotted {
  width: 7px;
  height: 7px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 50%;
  transform: translateY(-1px);
}

.text-box {
  max-width: 13em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
